/* eslint-disable func-names */
/**
 * Thin wrapper around Choices.js
 * https://github.com/jshjohnson/Choices
 * https://gist.github.com/leastbad/e6773a0c800e96ba7c76e342e4e40ef7
 */
import { Controller } from 'stimulus'
import * as Choices from 'choices.js'

export default class extends Controller {
  static targets = ['select', 'options']

  initialize () {
    this.element['choices'] = this
    this.options = this.options.bind(this)
    this.optionsReducer = this.optionsReducer.bind(this)
  }

  connect () {
    setTimeout(this.setup.bind(this), 5)
  }

  setup () {
    this.choices = new Choices(this.selectTarget, this.options())
    this.input = this.element.querySelector('input')
  }

  disconnect () {
    try {
      this.choices.destroy()
    } catch {}
    this.choices = undefined
  }

  options () {
    return 'silent renderChoiceLimit maxItemCount addItems removeItems removeItemButton editItems duplicateItemsAllowed delimiter paste searchEnabled searchChoices searchFloor searchResultLimit position resetScrollPosition addItemFilter shouldSort shouldSortItems placeholder placeholderValue prependValue appendValue renderSelectedChoices loadingText noResultsText noChoicesText itemSelectText addItemText maxItemText'
      .split(' ')
      .reduce(this.optionsReducer, {})
  }

  optionsReducer (accumulator, currentValue) {
    if (this.element.dataset[currentValue])
      accumulator[currentValue] = this.element.dataset[currentValue]
    return accumulator
  }
}
