import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["wrapper", "container", "background"];
  static values = { open: Boolean, time: Object, modalTime: Object };

  // timeout handling for kiosk screens
  // on load start 4minute timer
  // at 4 minutes display modal
  // on modal popup start 60 second timer
  // if any button is click modal timer gets cleared and 4 minute timer is reset
  // else user is moved back to starting page
  connect(){
    console.log('kiosk timeout modal connected')
    this.resetTimer()
  }

  showModal() {
    console.log('timer reset')
    this.modalTime = setTimeout(() => this.resetPath(), 60000)
    this.view()
  }

  resetPath() {
    location.href = '/kiosk'
  }

  resetTimer() {
    console.log('timer reset')
    clearTimeout(this.time);
    this.time = setTimeout(() => this.showModal(), 240000)
  }

  view(e) {
    this.openValue = true;

    if (this.openValue) {
      document.body.classList.add("no-scroll");
      this.wrapperTarget.insertAdjacentHTML("afterbegin", this.template());
    }
  }

  close(e) {
    console.log("close called")
    clearTimeout(this.modalTime)
    this.resetTimer()
    e.preventDefault();

    if (this.openValue) {
      document.body.classList.remove("no-scroll");

      if (this.hasContainerTarget) {
        this.containerTarget.remove();
      }
      this.openValue = false;
      this.resetTimer()
    }
  }

  closeBackground(e) {
    console.log("close bg");
    console.log(e.target);
    console.log(this.backgroundTarget);
    if (e.target === this.backgroundTarget) {
      this.close(e);
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27) {
      this.close(e);
    }
  }

  template() {
    return `
        <div data-kiosk-modal-target='container'>
            <div style="display: block; overflow: scroll" class="modal" tabindex="-1" role="dialog">
                <div class="modal-backdrop" data-kiosk-modal-target='background' data-action='click->kiosk-modal#closeBackground'></div>
                <div class="modal-dialog" role="document">
                        <div class="modal-content" style="background-color: rgb(252, 250, 240)">
                        <div class="modal-header align-items-center">
                            <h1 class="font-weight-bold"></h1>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" data-action='click->kiosk-modal#close'>&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-center" data-kiosk-modal-target='content'>
                            It's been a little while. Are you still here?
                            <br />
                            <br />
                            <button class="btn btn-dark" data-action='click->kiosk-modal#close'>I'm Still here!</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    `;
  }
}
