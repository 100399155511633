import {Controller} from "stimulus";
// packaging this with webpacker was causing problems... including on the page via JS works dandy
// import * as OV from 'online-3d-viewer';

export default class extends Controller {
  static targets = ["wrapper"]
  static values = {obj: String, mtl: String}
  viewer = null

  connect() {
    console.log('three d viewer connected')
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
      ] = this // reference the stim controller on the dom element

    // https://github.com/kovacsv/Online3DViewer/wiki/Embed-the-Viewer-Engine
    // get the parent element of the viewer
    let parentDiv = this.element;

    // initialize the viewer with the parent element and some parameters
    this.viewer = new OV.EmbeddedViewer (parentDiv, {
      onModelLoaded : () => {
        this.flipModel()
      }
    });

    // load a model providing model urls
    console.log('obj', this.objValue)
    console.log('mtl', this.mtlValue)
    this.viewer.LoadModelFromUrlList ([
      this.objValue,
      this.mtlValue
    ]);
  }

  flipModel(){
    this.viewer.viewer.SetUpVector (OV.Direction.Z, true)
  }
}
