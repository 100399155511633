import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["email"];

  validateEmail(event) {
    const emailInput = this.emailTarget;
    const emailValue = emailInput.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(emailValue)) {
      emailInput.classList.add('border', 'border-danger');

      let errorElement = emailInput.parentNode.querySelector('.text-danger');
      if (!errorElement) {
        errorElement = document.createElement('div');
        errorElement.classList.add('text-danger', 'text-sm', 'mt-1');
        emailInput.parentNode.insertBefore(errorElement, emailInput.nextSibling);
      }
      errorElement.textContent = 'Please enter a valid email address.';

      event.preventDefault();
    }
  }
}
