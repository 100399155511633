// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "../stylesheets/application.scss";
import * as Sentry from "@sentry/browser";
import "@kollegorna/cocoon-vanilla-js";
import "bootstrap.native";
document.addEventListener("turbolinks:load", () => {
  BSN.initCallback(document.body);
});

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import ahoy from "ahoy.js";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
});

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// configured in routes - some ad blockers block ahoy...
ahoy.configure({
  visitsUrl: `/hide1a/visits`,
  eventsUrl: `/hide1a/events`,
});

ahoy.trackView();
ahoy.trackClicks("a, button, input[type=submit]");
ahoy.trackSubmits("form");
ahoy.trackChanges("input, textarea, select");

import "controllers";
require.context("../images", true);

// facebook events - also see application.html.haml head
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
