import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["wrapper", "container", "content", "background"];
  static values = { url: String, open: Boolean };

  view(e) {
    this.openValue = true;

    if (this.openValue) {
      document.body.classList.add("no-scroll");
      this.getContent(this.urlValue);
      this.wrapperTarget.insertAdjacentHTML("afterbegin", this.template());
    }
  }

  close(e) {
    console.log("close called");
    e.preventDefault();

    if (this.openValue) {
      document.body.classList.remove("no-scroll");

      if (this.hasContainerTarget) {
        this.containerTarget.remove();
      }
      this.openValue = false;
    }
  }

  closeBackground(e) {
    console.log("close bg");
    console.log(e.target);
    console.log(this.backgroundTarget);
    if (e.target === this.backgroundTarget) {
      this.close(e);
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27) {
      this.close(e);
    }
  }

  getContent(url) {
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.text();
        }
      })
      .then((html) => {
        this.contentTarget.innerHTML = html;
      });
  }

  template() {
    return `
        <div data-order-details-modal-target='container'>
            <div style="display: block; overflow: scroll" class="modal" tabindex="-1" role="dialog">
                <div class="modal-backdrop" data-order-details-modal-target='background' data-action='click->order-details-modal#closeBackground'></div>
                <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-header align-items-center">
                            <h1 class="font-weight-bold">Order Details</h1>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" data-action='click->order-details-modal#close'>&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" data-order-details-modal-target='content'>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    `;
  }
}
