import {Controller} from "stimulus";
import {setFormattedPrice, setSelectedValueEnum} from "./helpers";

export default class extends Controller {
  static targets = [ "accessorySize", "accessorySizeI18n", "rowPriceDisplay", "rowBasePriceDisplay", "printHeight", "figurePrice", "priceType", "description"]
  static values = { participantId: String, total: Number, id: String, baseTotal: Number }

  totalValueChanged() {
    // update the parent pricing row
    // console.log('update the parent pricing row', this.participantIdValue, document.querySelector(`[data-pricing-row-participant-id-value="${this.participantIdValue}"].pricing-row`))
    this.participantPricingRow().calculateAccessoryTotal()
  }

  participantPricingRow() {
    return document.querySelector(`[data-pricing-row-participant-id-value="${this.participantIdValue}"].pricing-row`)?.pricingRow
  }

  connect() {
    console.log('pricing accessory row connected', this.identifier)
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
      ] = this // reference the stim controller on the dom element
    this.participantPricingRow().calculatePrintHeightRange()
    this.calculatePrice()
  }

  toggleFigurePrice() {
    console.log('toggle figure price')
    this.calculatePrice()
  }

  relativeScale() {
    document.querySelector('#scalingController').scaling.relativeScaleTarget.value
  }

  pricingTotal() {
    return document.querySelector('#pricingTotal')?.pricing
  }

  calculatePrice() {
    console.log('calculatePrice accessory', this.element)

    const potentialQuantityDiscount = Number(this.pricingTotal().otherFigureCountValue) + (Number(this.pricingTotal().participantCountValue) * Number(this.participantPricingRow().rowQuantityTarget.innerText))

    // figurePrice = quantity * figurePrice[print_height]
    let quantity = null
    switch(potentialQuantityDiscount) {
      case 1:
        quantity = '1'
        break;
      default:
        quantity = 'more'
        break;
    }

    // figurePrice = quantity * figurePrice[print_height]
    //const dataToGet = this.priceTypeTarget.innerText
    // console.log(this.figurePriceTarget.selectedOptions[0].dataset['price'], this.accessorySizeTarget.innerText, JSON.parse(this.figurePriceTarget.selectedOptions[0].dataset['price'])[this.accessorySizeTarget.innerText])
    if (this.figurePriceTarget.selectedOptions[0].dataset['price']) {
      this.totalValue = JSON.parse(this.figurePriceTarget.selectedOptions[0].dataset['price'])[this.accessorySizeTarget.innerText][quantity]
      this.baseTotalValue = JSON.parse(this.figurePriceTarget.selectedOptions[0].dataset['price'])[this.accessorySizeTarget.innerText]['1']

      setFormattedPrice(this.rowBasePriceDisplayTarget, this.baseTotalValue)
      setFormattedPrice(this.rowPriceDisplayTarget, this.totalValue)
    }

    if(this.accessorySizeI18nTarget.innerText === 'Glasses' && ['price_4', 'price_5', 'price_6', 'price_7'].includes(this.figurePriceTarget.selectedOptions[0].dataset['enum']))
      if (!document.body.dataset.alerted) {
        window.alert('Glasses not allowed on figure under 8 inches, please adjust')
        document.body.dataset.alerted = true // only show this warning once on the page
      }

    // print height display gets set based on figurePrice
    // this.printHeightDisplayTarget.innerText = this.figurePriceTarget.selectedOptions[0].dataset.display

  }

}

