import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["quantity"]

  connect() {
    console.log('quantity', this.identifier)
  }

  up() {
    this.quantityTarget.value = Number(this.quantityTarget.value) + 1
  }

  down() {
    if(this.quantityTarget.value > 1)
      this.quantityTarget.value = Number(this.quantityTarget.value) - 1
  }
}
