export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`)
  if (element) {
    return element.getAttribute("content")
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root
    root = document
  }
  return root.querySelector(selector)
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export function show(target) {
  target.classList.remove('d-none')
}

export function hide(target) {
  target.classList.add('d-none')
}

// not generic! looks for dataset.enum!
export function setSelectedValueEnum(selectObj, valueToSet) {
  // console.log('setselected ENUM', selectObj, valueToSet)
  for (var i = 0; i < selectObj.options.length; i++) {
    if (selectObj.options[i].dataset.enum == valueToSet) {
      selectObj.options[i].selected = true;
      return;
    }
  }
}

export function setFormattedPrice(domElement, price) {
  // console.log("setting price for", domElement, price)
  domElement.innerText = new Intl.NumberFormat('en-US',
    {style: 'currency', currency: 'USD'}
  ).format(price)
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
