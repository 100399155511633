import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["state", 'country'];

  connect() {
    console.log("Country states controller connected");
  }

  updateState() {
    fetch(`/addresses/states?country_code=${this.countryTarget.value}`)
    .then(response => response.json())
    .then(data => this.updateStateDropdown(data))
    .catch(
      error => console.error('Error:', error));
  }

  updateStateDropdown(data){
    const stateDropdown = this.stateTarget;
    stateDropdown.innerHTML = "";

    const emptyOption = document.createElement("option");
    emptyOption.value = "";
    emptyOption.textContent = "";
    stateDropdown.appendChild(emptyOption);

    data.forEach(stateInfo => {
      const option = document.createElement("option");
      for (const key in stateInfo[2]) {
        option.setAttribute(key, stateInfo[2][key]);
      }
      option.value = stateInfo[0];
      option.textContent = stateInfo[1];
      stateDropdown.appendChild(option);
    });
  }
}
