import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    console.log("Connected to Payment Controller");
    (async () => {
      const payments = Square.payments(
        window.App.SQUARE_APP_ID,
        window.App.SQUARE_LOCATION_ID
      )
      const cardOptions = {
        style: {
          input: {
            backgroundColor: 'white'
          }
        }
      };
      try {
        const card = await payments.card(cardOptions);
        await card.attach('#card')
        const payButton = document.getElementById('pay');
        payButton.addEventListener('click', async () => {
          const result = await card.tokenize();
          const cardNonce = result.token;

          console.log(cardNonce);

          this.sendPaymentData(cardNonce);
        })
      }
      catch (e) {
        console.error(e)
      }
    })();
  }

  async sendPaymentData(cardNonce) {
    this.element.querySelector("#pay").disabled = true
    try {
      const response = await fetch('/payments/process_card_payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({ card_nonce: cardNonce, id: document.getElementById('order_id').textContent || document.getElementById('order_id').value })
      });
      const responseData = await response.json();
      if (response.ok) {
        if (responseData.success) {
          window.location.href = responseData.url;
        } else {
          const cardMessage = document.getElementById('cardMessage');
          cardMessage.style.color = 'red'
          cardMessage.textContent = responseData.error;
          cardMessage.style.display = 'block';
        }
      } else {
        console.error('Failed to process payment');
      }
    } catch (error) {
      console.error('Error processing payment:', error);
    }
    this.element.querySelector("#pay").disabled = false
  }
}
