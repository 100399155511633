import { Controller } from "stimulus";
import * as PhotoSwipe from "photoswipe";
import * as PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";

export default class extends Controller {
  // repurposed from https://awesomeprogrammer.com/blog/2019/02/16/photoswipe-gallery-with-stimulusjs-on-rails/
  static targets = ["picture", "galleryElement"];

  connect() {
    // this.gallery is set in `onImageClick` so we always have a reference
    // to our current gallery instance
    this.gallery = undefined;
    this.onImageClick();
  }

  onImageClick() {
    // event?.preventDefault()

    const galleryWrapper = this.galleryElementTarget;

    var options = {
      // we don't want browser history for the sake of simplicity
      history: false,
      closeOnScroll: false,
      closeOnEscape: false,
      closeOnSelect: false,
      closeOnVerticalDrag: false,
      closeElClasses: [],
      modal: false,
      // and I'm assuming we have unique links in each gallery
      // index: this.items.findIndex(item => item.src === event.currentTarget.getAttribute('href'))
    };

    var gallery = new PhotoSwipe(
      galleryWrapper,
      PhotoSwipeUI_Default,
      this.items,
      options
    );

    // PhotoSwipe requires width and height do be declared up-front
    // let's work around that limitation, references:
    // https://github.com/dimsemenov/PhotoSwipe/issues/741#issuecomment-430725838
    gallery.listen("beforeChange", function () {
      const src = gallery.currItem.src;

      const image = new Image();
      image.src = src;

      image.onload = () => {
        gallery.currItem.w = image.width;
        gallery.currItem.h = image.height;

        // Hide the top bar
        gallery.options.barsSize.top = 0;

        gallery.updateSize();
      };
    });

    gallery.init();
    this.gallery = gallery;
  }

  get items() {
    return this.pictureTargets.map(function (item) {
      return {
        src: item.getAttribute("href"),
        title: item.getAttribute("title"),
        w: 0,
        h: 0,
      };
    });
  }

  prev() {
    this.gallery.prev();
  }

  next() {
    this.gallery.next();
  }
}
