import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"];

  connect() {
    // console.log('select urls connected', this.selectTarget)
  }

  changeEvent() {
    const redirect = this.selectTarget.selectedOptions[0].dataset["url"];
    console.log(this.selectTarget.selectedOptions[0]);
    console.log("redirecting to", redirect);
    window.location = redirect;
  }
}
