import {Controller} from "stimulus";
import {getMetaValue} from "./helpers";

export default class extends Controller {
  static targets = ["wrapper"]

  connect() {
    console.log('toggle buttonconnected')
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
      ] = this // reference the stim controller on the dom element
  }

  toggleDisplay() {
    console.log('toggle display')
    this.wrapperTarget.classList.toggle('d-none')
  }
}
