import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["wrapper", "container", "background"];
  static values = { open: Boolean };

  connect(){
    console.log('terms modal connected')
  }

  view(e) {
    this.openValue = true;

    if (this.openValue) {
      document.body.classList.add("no-scroll");
      this.wrapperTarget.insertAdjacentHTML("afterbegin", this.template());
    }
  }

  close(e) {
    console.log("close called");
    e.preventDefault();

    if (this.openValue) {
      document.body.classList.remove("no-scroll");

      if (this.hasContainerTarget) {
        this.containerTarget.remove();
      }
      this.openValue = false;
    }
  }

  closeBackground(e) {
    console.log("close bg");
    console.log(e.target);
    console.log(this.backgroundTarget);
    if (e.target === this.backgroundTarget) {
      this.close(e);
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27) {
      this.close(e);
    }
  }

  template() {
    return `
        <div data-terms-modal-target='container'>
            <div style="display: block; overflow: scroll" class="modal" tabindex="-1" role="dialog">
                <div class="modal-backdrop" data-terms-modal-target='background' data-action='click->terms-modal#closeBackground'></div>
                <div class="modal-dialog" role="document">
                        <div class="modal-content" style="background-color: rgb(252, 250, 240)">
                        <div class="modal-header align-items-center">
                            <h1 class="font-weight-bold"></h1>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" data-action='click->terms-modal#close'>&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" data-terms-modal-target='content'>
                            <iframe src='https://www.dopl.com/terms-conditions' style="height: 500px; width: 100%; border: 0;" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    `;
  }
}
