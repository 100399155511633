import {Controller} from "stimulus";
import {getMetaValue} from "./helpers";

export default class extends Controller {
  static targets = ["wrapper", 'userField']
  static values = {admin: String, list: String}

  connect() {
    console.log('order discount connected')
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
      ] = this // reference the stim controller on the dom element
  }

  challengeUser(){
    console.log('perm check!')
    let code = window.prompt('Please enter your Manager Code');
    const arr = JSON.parse(this.listValue)
    const result = arr.find(e => Object.keys(JSON.parse(e)).includes(code))
    if(result) {
      console.log('match found!')
      this.wrapperTarget.classList.remove('d-none')
      // set user field
      this.userFieldTarget.value = JSON.parse(result)[code]
    } else
      window.alert('no match found, please try again')
  }

  toggleDisplay() {
    console.log('toggle display')
    if(this.adminValue === 'true')
      this.wrapperTarget.classList.toggle('d-none')
    else
      this.challengeUser()
  }
}
