import { Controller } from 'stimulus';
import Rails from "@rails/ujs"
import debounce from 'lodash/debounce'
export default class extends Controller {
  static targets = ["query", "orderItems"]
  static values = { path: String }
  connect() {
    // Disable enter keypresses on the search form
    console.log("connected production search controller", this.pathValue)
    this.search = debounce(this.search, 500)
    this.queryTarget.addEventListener('keypress', function (e) {
      if (e.keyCode === 13 || e.which === 13) {
        e.preventDefault();
        return false;
      }
    })
  }

  search() {
    // Typeahead search on the order search form
    const value = this.queryTarget.value
    console.log("production searching + " + value)
    Rails.ajax({
      type: "GET",
      url: `${this.pathValue}?query=${value}`,
      success: (_data, _status, xhr) => {
        this.orderItemsTarget.outerHTML = xhr.response
      }
    })
  }


}
