import {Controller} from "stimulus";
import {getMetaValue} from "./helpers";

export default class extends Controller {
  static targets = [ "select"]
  static values = {id: String}

  initialize() {
    // console.log('status change connected')
  }

  toggleSelect() {
    // console.log('toggle select', this.selectTarget.selectedOptions[0], getMetaValue("csrf-token"))
    this.selectTarget.disabled = true

   fetch(`/staff/order_items/${this.idValue}`, {
     method: 'PUT',
     body: JSON.stringify({order_item: {difficulty: this.selectTarget.selectedOptions[0].dataset.text}}),
     headers: { "X-CSRF-Token": getMetaValue("csrf-token"), "Content-Type": "application/json", 'X-Requested-With': 'XMLHttpRequest' }
   }).then(resp=> {
     console.log('successfully updated', resp)
     this.selectTarget.disabled = false
   }).catch((e)=>{
     console.log('update failed', e)
     this.selectTarget.disabled = false
   })
  }

}
