/**
 * Thin wrapper around flatpickr datetime picker
 * https://flatpickr.js.org/
 */
import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {
  connect() {
    this.config = {
      altInput: true,
    }

    if (!this.element.value) {
      this.config.defaultDate = "today";
    }

    super.connect()

    const flatpickrInstance = flatpickr(this.element, {
      // Add any flatpickr options here
      onChange: this.handleDateChange.bind(this)
    });
    this.element.flatpickr = flatpickrInstance;
  }

  handleDateChange(selectedDates, dateStr, instance) {
    // Emit a custom event to notify the Stimulus controller's element
    // console.log('emitting date:selected', dateStr)
    this.element.dispatchEvent(new CustomEvent("date:selected", {
      detail: {
        date: dateStr
      }
    }));
  }
}
