import {Controller} from "stimulus";
import {setFormattedPrice} from "./helpers";

export default class extends Controller {
  static targets = [ "totalDisplay", "quantity", "subTotalDisplay"]
  static values = { subTotal: Number, participantCount: Number, otherFigureCount: Number }

  connect() {
    // console.log('pricing connected', this.identifier)
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
      ] = this // reference the stim controller on the dom element
    this.participantCountValueChanged()
  }

  participantCountValueChanged() {
    // update scaling controller viable scaling type options
    document.querySelector('#scalingController').scaling.adjustPossibleScaleType()
  }

  calcTotal() {
    // console.log("calc total for pricing")
    let workingTotal = 0
    // iterate through all the pricing rows to get pricing from each of them (they pull accessories up)
    document.querySelectorAll('.pricing-row').forEach(r=> {
      if (r?.pricingRow?.hasTotalValue) {
        // console.log('adding pricing row', r.pricingRow.totalValue)
        workingTotal += r.pricingRow.totalValue
      }
    })

    // add in platform price (if relevent)
    const platform = document.querySelector('#scalingController').platform
    if(platform.hasTotalValue) {
      // console.log('adding platform ', platform.totalValue)
      workingTotal += platform.totalValue
    }

    this.subTotalValue = workingTotal

    setFormattedPrice(this.subTotalDisplayTarget, this.subTotalValue)
    setFormattedPrice(this.totalDisplayTarget, this.subTotalValue * this.quantityTarget.value)
  }

}
