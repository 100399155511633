import {Controller} from "stimulus";
import {getMetaValue} from "./helpers";

export default class extends Controller {
  static targets = [ "select"]
  static values = {id: String}

  connect() {
    this.setColor()
  }

  toggleSelect() {
    console.log('toggle select', this.selectTarget.selectedOptions[0], getMetaValue("csrf-token"))
    this.selectTarget.disabled = true

   fetch(`/staff/calibration_files/${this.idValue}/toggle_status`, {
     method: 'PATCH',
     body: JSON.stringify({calibration_file: {status: this.selectTarget.selectedOptions[0].dataset.text}}),
     headers: { "X-CSRF-Token": getMetaValue("csrf-token"), "Content-Type": "application/json", 'X-Requested-With': 'XMLHttpRequest' }
   }).then(resp=> {
     console.log('successfully updated', resp)
     this.selectTarget.disabled = false
   }).catch((e)=>{
     console.log('update failed', e)
     this.selectTarget.disabled = false
   })
    this.setColor()
  }

  setColor(){
    console.log('setcolor', this.selectTarget)
    if(this.selectTarget) {
      if(this.selectTarget.selectedOptions[0]) {
        if (this.selectTarget.selectedOptions[0].dataset.text == 'rejected') {
          this.selectTarget.classList.add('stop')
          this.selectTarget.classList.remove('go')
        } else if (this.selectTarget.selectedOptions[0].dataset.text == 'approved') {
          this.selectTarget.classList.add('go')
          this.selectTarget.classList.remove('stop')
        }
      }
    }
  }
}
