import {Controller} from "stimulus";

export default class extends Controller {
  static targets = [ "refHeight", "scale", "printHeight", "sizeError", 'height', 'poseHeight', "name", "poseHeightCalculated"]
  static values = { participantId: String, printHeight: Number }

  printHeightValueChanged() {
    // console.log('updating print height value', this.printHeightValue, this.participantRow().printHeightTarget)
    this.printHeightTarget.value = this.printHeightValue
    if(this.participantRow())
      this.participantRow().printHeightTarget.value = this.printHeightValue
      if (document.querySelector('input[name="order_item[ornament_type]"]:checked')?.value == 'candy_cane') {
        this.participantRow().printHeightTarget.value = '4.48819'
      }
  }

  participantRow() {
    return document.querySelector(`[data-participants-id-value="${this.participantIdValue}"]`)?.participants
  }

  connect() {
    console.log('scaling row connected', this.identifier)
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
      ] = this // reference the stim controller on the dom element
    this.calculatePrintHeight()

    if(!this.scaleTarget.value)
      this.getScaleHeight()
  }

  togglePrintHeight() {
    console.log('toggle print height')
    this.calculatePrintHeight()
  }

  // pull from the 'parent' scaling controller - called on change to relative scale in parent controller
  getScaleHeight() {
    this.scaleTarget.value = document.querySelector('#scalingController').scaling.relativeScaleTarget.value
    this.calculatePrintHeight()
  }

  relativeScale() {
    document.querySelector('#scalingController').scaling.relativeScaleTarget.value
  }

  calculatePrintHeight() {
    console.log('calculatePrintHeight', this.poseHeightCalculatedTarget.value, this.scaleTarget.value)

    // pose height * scale = print height
    this.printHeightValue = this.poseHeightCalculatedTarget.value * this.scaleTarget.value

    // set or clear warning if print height is greater than 14 or less than 4
    if(this.printHeightValue > 14 || this.printHeightValue < 4)
      this.sizeErrorTarget.classList.remove('d-none')
    else
      this.sizeErrorTarget.classList.add('d-none')

    // price height is the larger of ref & pose height
    const pricingHeightMax = Math.max(this.refHeightTarget.value, this.poseHeightCalculatedTarget.value)

    // push value to any matching pricing row (singular) - id matches and .pricing-row
    // this is ref height * scale - not pose height * scale like print height - price height is different!!!!!!!!!!!!!!!
    const pricingRow = document.querySelector(`[data-pricing-row-participant-id-value="${this.participantIdValue}"].pricing-row`)?.pricingRow
    // console.log(pricingRow, document.querySelector(`[data-pricing-row-participant-id-value="${this.participantIdValue}"].pricing-row`))
    pricingRow?.setPrintHeight(pricingHeightMax * this.scaleTarget.value) // we have a race condition sometimes on init that requires the null check
  }

}
