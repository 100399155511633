import {Controller} from "stimulus";
import {getMetaValue} from "./helpers";

export default class extends Controller {
  static targets = [ "select", "nickname"]
  static values = {id: String}

  initialize() {
    console.log('scan type connected')
  }

  toggleSelect() {
    console.log('toggle select', this.selectTarget.selectedOptions[0], getMetaValue("csrf-token"))
    this.selectTarget.disabled = true

   fetch(`/staff/scans/${this.idValue}`, {
     method: 'PUT',
     body: JSON.stringify({scan: {scan_type: this.selectTarget.selectedOptions[0].dataset.text}}),
     headers: { "X-CSRF-Token": getMetaValue("csrf-token"), "Content-Type": "application/json", 'X-Requested-With': 'XMLHttpRequest' }
   }).then(resp=> {
     console.log('successfully updated', resp)
     this.selectTarget.disabled = false
   }).catch((e)=>{
     console.log('update failed', e)
     this.selectTarget.disabled = false
   })
  }

  setNickname() {
    console.log('nickname change', this.nicknameTarget.value, getMetaValue("csrf-token"))

    // this.nicknameTarget.disabled = true

    fetch(`/staff/scans/${this.idValue}`, {
      method: 'PUT',
      body: JSON.stringify({scan: {nickname: this.nicknameTarget.value}}),
      headers: { "X-CSRF-Token": getMetaValue("csrf-token"), "Content-Type": "application/json", 'X-Requested-With': 'XMLHttpRequest' }
    }).then(resp=> {
      console.log('successfully updated', resp)
      // this.nicknameTarget.disabled = false
    }).catch((e)=>{
      console.log('update failed', e)
      // this.nicknameTarget.disabled = false
    })
  }
}
