import {Controller} from "stimulus";
import {hide, show} from "./helpers";

export default class extends Controller {
  static targets = [ "select"]
  static values = {id: String}

  connect() {
    console.log('product type select connected')
    this.toggleSelect() // run on page load
  }

  toggleSelect() {
    console.log('toggle select', this.selectTarget.selectedOptions[0])

    if(this.selectTarget.selectedOptions[0]?.dataset?.type === 'Ornament'){
      show(document.getElementById('ornament'))
      if(document.getElementById('order_item_scale_type_scale_to_figurine'))
        document.getElementById('order_item_scale_type_scale_to_figurine').checked = true

      hide(document.getElementById('scaling'))
      hide(document.getElementById('calculatedSizes'))
      if (document.querySelector('input[name="order_item[ornament_type]"]:checked')?.value == 'dog_santa_hat' || document.querySelector('input[type="radio"][value="pet"][name*="participants_attributes"]')?.checked){
        document.getElementById('order_item_ornament_type_dog_santa_hat').parentElement.style.display = 'block';
        document.getElementById('order_item_ornament_type_candy_cane').parentElement.style.display = 'none';
        document.getElementById('order_item_ornament_type_santa_hat').parentElement.style.display = 'none';
      } else {
        document.getElementById('order_item_ornament_type_dog_santa_hat').parentElement.style.display = 'none';
        document.getElementById('order_item_ornament_type_candy_cane').parentElement.style.display = 'block';
        document.getElementById('order_item_ornament_type_santa_hat').parentElement.style.display = 'block';
      }
    } else if (this.selectTarget.selectedOptions[0]?.dataset?.type === 'Digital') {
      if(document.getElementById('order_item_scale_type_scale_to_figurine'))
        document.getElementById('order_item_scale_type_scale_to_figurine').checked = true

      const oiFigureSize = document.getElementById('order_item_figure_size')
      for (var i = 0; i < oiFigureSize.options.length; i++) {
        if (oiFigureSize.options[i].value === 'inch_14') {
          oiFigureSize.options[i].selected = true;
        }
      }

      document.getElementById('scalingController').scaling.toggleFigureSize()

      hide(document.getElementById('ornament'))
      hide(document.getElementById('scaling'))
      hide(document.getElementById('calculatedSizes'))
    } else { // if (this.selectTarget.selectedOptions[0].dataset.type === 'Miniature') {
      hide(document.getElementById('ornament'))

      show(document.getElementById('scaling'))
      show(document.getElementById('calculatedSizes'))
    }
  }
}
