import { Controller } from 'stimulus';
import Rails from "@rails/ujs"
export default class extends Controller {
  static targets = ["query", "customers"]
  connect() {
    // Disable enter keypresses on the search form
    this.queryTarget.addEventListener('keypress', function (e) {
      if (e.keyCode === 13 || e.which === 13) {
        e.preventDefault();
        return false;
      }
    })
  }

  search() {
    // Typeahead search on the customer search form
    const value = this.queryTarget.value
    Rails.ajax({
      type: "GET",
      url: `/staff/customers/search?query=${value}`,
      success: (_data, _status, xhr) => {
        this.customersTarget.innerHTML = xhr.response
      }
    })
  }


}
