import {Controller} from "stimulus";
import {getMetaValue} from "./helpers";

export default class extends Controller {
  static targets = [ "select", "nickname"]
  static values = {id: String}

  initialize() {
    console.log('scan image connected')
  }

  toggleSelect() {
    console.log('toggle select', this.selectTarget.selectedOptions[0], getMetaValue("csrf-token"))
    this.selectTarget.disabled = true

   fetch(`/staff/scans/${this.idValue}`, {
     method: 'PUT',
     body: JSON.stringify({scan: {default_image: this.selectTarget.selectedOptions[0].value}}),
     headers: { "X-CSRF-Token": getMetaValue("csrf-token"), "Content-Type": "application/json", 'X-Requested-With': 'XMLHttpRequest' }
   }).then(resp=> {
     console.log('successfully updated', resp)
     this.selectTarget.disabled = false
     window.location.reload(true)
   }).catch((e)=>{
     console.log('update failed', e)
     this.selectTarget.disabled = false
   })
  }
}
