import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    console.log('connecting select_scan_controller', this.element)
    const source = this.element.getAttribute('data-source');
    if (source === 'add scan') {
    }
    else {
      let today = new Date().toISOString().slice(0, 10);
      this.handleDateSelected(today)
    }
    this.selectElement = document.getElementById('order_item_scan_id');
    this.element.addEventListener("date:selected", this.handleDateSelected.bind(this));
  }

  handleDateSelected(event) {
    console.log('handleDateSelected', event?.detail?.date)
    let date = null;
    if (event.detail) {
      date = event.detail.date
    }
    else
    {
      date = event
    }
    const selectedDate = date;
    const orderId = document.getElementById('order_item_order_id').value

    fetch(`/staff/order_items/get_scans?date=${selectedDate}&order_id=${orderId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.updatePrimarySelectOptions(data.primary_scans);
        this.createAdditionalSelectElement(data.additional_scans);
      })
      .catch(error => {
        console.error('There was a problem fetching the scans:', error);
      });
  }

  createAdditionalSelectElement(scans) {
    console.log('createAdditionalSelectElement', scans)
    const data = document.querySelector('#scan_json')
    data.value = JSON.stringify(scans)
  }

  replaceSelect(event) {
    console.log('replaceSelect', this.element, event)
    // this clears values on every click - you can't set a scan value on an additional scan and save it - every click is cleared by this event.
    // this should be refactored to run when the scan capture date changes (or the fetch lookup callback comes back) and replace the values in all the select elements that exist under the #scans div
    // or where the select element name matches 'order_item[order_item_scans_attributes]'
    const data = document.querySelector('#scan_json')
    const scans = JSON.parse(data.value)
    const selectedScanId = this.element.parentNode.nextElementSibling.value
    const selectedScanName = this.element.parentNode.nextElementSibling.nextElementSibling.value
    const current_value = this.element.selectedOptions[0].value
    this.element.innerHTML = '';

    if (selectedScanName) {
      const option = document.createElement('option');
      option.value = selectedScanId;
      option.text = selectedScanName;
      this.element.appendChild(option);
    }

    if (scans.length == 0 && !selectedScanName) {
      const emptyOption = document.createElement('option');
      emptyOption.value = '';
      emptyOption.text = ' ';
      this.element.appendChild(emptyOption);
    }

    scans.forEach(scan => {
      if (scan.id !== selectedScanId) {
        const option = document.createElement('option');
        option.value = scan.id;
        option.text = scan.label;
        if (scan.id == current_value) {
          option.selected = true
        }
        this.element.appendChild(option);
      }
    });
  }

  updatePrimarySelectOptions(scans) {
    const selectedScanId = document.getElementById('primary_scan_id').value
    const selectedScanName = document.getElementById('primary_scan_name').value
    this.selectElement.innerHTML = '';
    if (selectedScanName) {
      const option = document.createElement('option');
      option.value = selectedScanId;
      option.text = selectedScanName;
      option.selected = true
      this.selectElement.appendChild(option);
    } else {
      const emptyOption = document.createElement('option');
      emptyOption.value = '';
      emptyOption.text = ' ';
      this.selectElement.appendChild(emptyOption);
    }

    scans.forEach(scan => {
      if (scan.id !== selectedScanId) {
        const option = document.createElement('option');
        option.value = scan.id;
        option.text = scan.label;
        this.selectElement.appendChild(option);
      }
    });
  }
}
