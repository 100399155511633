import {Controller} from "stimulus";
import {uuidv4} from "./helpers";

export default class extends Controller {
  static targets = [ "accessorySize", "description" ]
  static values = { id: String, participantId: String }

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
      ] = this // reference the stim controller on the dom element

    this.participantIdValue = this.element.parentNode.parentNode.parentNode.participants.idValue

    console.log('accessory connected', this.identifier, this.participantIdValue)
    // add an accessory row to the pricing table

    // check if we have an ID for this element - if we do we don't need to create anything - we're working with a persisted item.
    // otherwise we need to add our rows to support this new element
    if (!this.hasIdValue) {
      this.idValue = uuidv4() // create an ID so we can use it for lookup when we remove items for cleanup
      // console.log('adding accessory pricing row', document.querySelector(`[data-pricing-row-participant-id-value="${this.participantIdValue}"].pricing-row`), this.idValue)
      let pricing = document.getElementById('accessoryPricingTemplate').content.firstElementChild.cloneNode(true)
      pricing.dataset.pricingAccessoryRowParticipantIdValue = this.participantIdValue
      pricing.dataset.pricingAccessoryRowIdValue = this.idValue

      document.querySelector(`[data-pricing-row-participant-id-value="${this.participantIdValue}"].pricing-row`).after(pricing)

      console.log(this.pricingAccessoryRow())
    }
  }

  pricingAccessoryRow() {
    return document.querySelector(`[data-pricing-accessory-row-id-value="${this.idValue}"].pricing-accessory-row`)?.pricingAccessoryRow
  }

  updateRelated() {
    // console.log('updating related fields - accessory', this.idValue, this.accessorySizeTarget)
    if(!this.pricingAccessoryRow())
      return

    this.pricingAccessoryRow().accessorySizeTarget.innerText = this.accessorySizeTarget.value
    if (this.accessorySizeTarget.value === 'glasses')
      this.pricingAccessoryRow().descriptionTarget.innerText = this.accessorySizeTarget[this.accessorySizeTarget.selectedIndex].text
    else
      this.pricingAccessoryRow().descriptionTarget.innerText = this.descriptionTarget.value
    this.pricingAccessoryRow().accessorySizeI18nTarget.innerText = this.accessorySizeTarget[this.accessorySizeTarget.selectedIndex].text

    this.pricingAccessoryRow().calculatePrice()

  }

  remove() {
    console.log('accessory removed')
    // remove pricing row

    document.querySelector(`[data-pricing-accessory-row-id-value="${this.idValue}"]`).remove()
  }

}
