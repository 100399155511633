import {Controller} from "stimulus";
import {setFormattedPrice, setSelectedValueEnum} from "./helpers";

export default class extends Controller {
  static targets = [ "printHeightDisplay", "figurePriceDisplay", "rowQuantity", "printHeight", "figurePrice", "priceType",
    "otherFigureQuantity", "figureBasePriceDisplay", "name"]
  static values = { participantId: String, tallest: Boolean, total: Number, accessoryTotal: Number, rowCost: Number, rowBaseCost: Number }

  totalValueChanged() {
    console.log('calling pricing calc total')
    if (this.pricingTotal()) // race condition on initial paint
      this.pricingTotal().calcTotal()
  }

  pricingTotal() {
    return document.querySelector('#pricingTotal')?.pricing
  }

  connect() {
    console.log('pricing row connected', this.identifier)

    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
      ] = this // reference the stim controller on the dom element

    if(this.rowQuantityTarget.innerText === '')
      this.rowQuantityTarget.innerText = this.pricingTotal().quantityTarget.value
    this.calculatePrice()
  }

  toggleFigurePrice() {
    console.log('toggle figure price')
    this.calculatePrice()
  }

  setPrintHeight(height) {
    console.log('set pricing print height', height)
    this.printHeightTarget.disabled = false // toggle disable so the value updates on the UI
    this.printHeightTarget.value = height
    this.printHeightTarget.disabled = true
    this.calculatePrintHeightRange()
    this.calculatePrice()

    // set the platform height if we're the tallest
    if(this.tallestValue) {
      // console.log('setting platform as I"m the tallest', this.participantIdValue, document.querySelector('#scalingController').platform.priceHeightTarget, this.figurePriceTarget.selectedOptions[0].dataset.enum)
      setSelectedValueEnum(document.querySelector('#scalingController').platform.priceHeightTarget, this.figurePriceTarget.selectedOptions[0].dataset.enum)
    }

    this.participantRow().priceHeightTarget.value = this.figurePriceTarget.selectedOptions[0].dataset.enum
  }

  participantRow() {
    return document.querySelector(`[data-participants-id-value="${this.participantIdValue}"]`).participants
  }

  setQuantity(quantity) {
    console.log('set quantity', quantity)
    this.rowQuantityTarget.textContent = quantity
    this.calculatePrice()
  }

  calculatePrintHeightRange() {
    console.log('calc print height range', this.printHeightTarget.value, (Math.round(this.printHeightTarget.value * 10) / 10).toFixed(1))
    // round to 1 decimal
    // match rounded value to print height data-enum value

    let print_height = this.printHeightTarget.value
    let ph_decimal = print_height % 1
    let ph_value = 0
    if(ph_decimal > 0.1){
        ph_value = Math.ceil(print_height)
    } else {
        ph_value = Math.floor(print_height)
    }
    setSelectedValueEnum(this.figurePriceTarget, `price_${ph_value}`)

    // console.log('update accessory rows', this.participantIdValue)
    document.querySelectorAll(`[data-pricing-accessory-row-participant-id-value="${this.participantIdValue}"].pricing-accessory-row`).forEach(row => {
      console.log(row)
      setSelectedValueEnum(row.pricingAccessoryRow.figurePriceTarget, `price_${Math.round(this.printHeightTarget.value)}`)
      row.pricingAccessoryRow.calculatePrice()
    })
  }

  relativeScale() {
    document.querySelector('#scalingController').scaling.relativeScaleTarget.value
  }

  calculatePrice() {
    console.log('calculatePrice')
    const otherFigureCount = this.pricingTotal() === undefined ? 0 : Number(this.pricingTotal().otherFigureCountValue)
    const participantCount = this.pricingTotal() === undefined ? 0 : Number(this.pricingTotal().participantCountValue)
    const potentialQuantityDiscount = otherFigureCount + (participantCount * Number(this.rowQuantityTarget.innerText))

    // figurePrice = quantity * figurePrice[print_height]
    let dataToGet = this.priceTypeTarget.innerText
    if (document.querySelector('input[name="order_item[ornament_type]"]:checked')?.value == 'dog_santa_hat') {
      dataToGet = 'petOrnaments'
    } else if (document.querySelector('input[name="order_item[ornament_type]"]:checked')?.value == 'candy_cane') {
      dataToGet = 'candyCaneOrnaments'
      console.log("using candyCaneOrnaments Pricing")
    } else if (document.querySelector('input[name="order_item[ornament_type]"]:checked')?.value == 'santa_hat') {
      // FIXME: this could probably be handled by checkign the main product type of ornament instead of checking the ornament type - at least to support additional ornament types
      dataToGet = 'ornaments'
      console.log("using ornaments Pricing")
    }
    console.log(`using ${dataToGet} Pricing`)
    let quantity = null
    switch(potentialQuantityDiscount) {
      case 1:
        quantity = '1'
        break;
      case 2:
        quantity = '2'
        break;
      default:
        quantity = 'more'
        break;
    }
    // console.log(this.figurePriceTarget.selectedOptions[0].dataset[dataToGet], dataToGet, quantity)
    if(this.figurePriceTarget.selectedOptions[0].dataset[dataToGet]) {
      this.rowBaseCostValue = JSON.parse(this.figurePriceTarget.selectedOptions[0].dataset[dataToGet])['1']
      this.rowCostValue = JSON.parse(this.figurePriceTarget.selectedOptions[0].dataset[dataToGet])[quantity]
      this.calcTotal()
    }

    // print height display gets set based on figurePrice
    this.printHeightDisplayTarget.innerText = this.figurePriceTarget.selectedOptions[0].dataset.display

    // special handling for ornament text display
    if(this.priceTypeTarget.innerText === 'ornaments') {
      if (this.printHeightDisplayTarget.innerText === '6 in')
        this.printHeightDisplayTarget.innerText = 'Ring'
      if (this.printHeightDisplayTarget.innerText === '4 in')
        if (document.querySelector('input[name="order_item[ornament_type]"]:checked')?.value == 'dog_santa_hat'){
          this.printHeightDisplayTarget.innerText = '4 in Dog Santa Hat'
        } else if (document.querySelector('input[name="order_item[ornament_type]"]:checked')?.value == 'candy_cane') {
          this.printHeightDisplayTarget.innerText = '4 in Candy Cane'
        } else {
          this.printHeightDisplayTarget.innerText = '4 in Santa Hat'
        }
    }
  }

  calculateAccessoryTotal() {
    // get all the accessory rows for this ID - sum the values and set the local value
    let localTotal = 0

    document.querySelectorAll(`[data-pricing-accessory-row-participant-id-value="${this.participantIdValue}"].pricing-accessory-row`).forEach(r => {
      console.log(r.pricingAccessoryRow)
      if (r?.pricingAccessoryRow && r.pricingAccessoryRow.hasTotalValue) {
        console.log('adding accessory row', r.pricingAccessoryRow.totalValue)
        localTotal += r.pricingAccessoryRow.totalValue
      }
    })

    this.accessoryTotalValue = localTotal
    console.log('row accessory value', this.accessoryTotalValue)
    this.calcTotal()
  }

  calcTotal() {
    this.totalValue = this.rowCostValue + this.accessoryTotalValue
    setFormattedPrice(this.figureBasePriceDisplayTarget, this.rowBaseCostValue)
    setFormattedPrice(this.figurePriceDisplayTarget, this.rowCostValue) // this does NOT include the accessories - just the row cost!
  }
}
