import {Controller} from "stimulus";
import {show, hide} from './helpers'

export default class extends Controller {
  static targets = [ "scanHeightFeet", "scanHeightInches", "relativeScale", "figureSize", "figureSizeWrapper","participantHeight", "scaleTo", "scaleToWrapper", "ornamentType",
                     "scanHeight", "referenceHeight", "printHeight", "scaleType", "scaleParticipant", "scaleParticipantsWrapper", "quantity", "scaleParticipantFigureSize", "denominator",
                     "rangeStart", "rangeEnd"]

  connect() {
    // console.log('scaling connected')
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
      ] = this // reference the stim controller https://leastbad.com/stimulus-power-move
    // we pull scaling and other info off this controller to the pricing row & participant row controllers based on ID for where we tag this controller to the parent element.

    this.toggleScaleType({target: {value: this.scaleTypeRadioValue()}})
  }

  scaleTypeRadioValue() {
    for(let i = 0; i < this.scaleTypeTargets.length; i++) {
      if(this.scaleTypeTargets[i].checked)
        return this.scaleTypeTargets[i].value;
    }
  }

  changeRelativeScale() {
    // update all the participant rows to have the updated scale
    document.querySelectorAll('.scaling-row').forEach(r=> {r.scalingRow?.getScaleHeight()})
    // update the platform controller size
    document.querySelectorAll('.scaling-row').forEach(r=> {r.scalingRow?.getScaleHeight()})
    this.element.platform.togglePriceHeight()
  }

  ornamentSelect() {
    console.log('ornament change', this.ornamentRadioValue())
    let size = 'inch_4'

    if(this.ornamentRadioValue() !== 'santa_hat' && this.ornamentRadioValue() !== 'dog_santa_hat' && this.ornamentRadioValue() !== 'candy_cane') { // 6 inches
      size = 'inch_6'
    }
    if (document.querySelector('input[name="order_item[ornament_type]"]:checked')?.value == 'candy_cane') {
      document.querySelector('[data-participants-target="printHeight"]').readOnly = false
      document.querySelector('[data-participants-target="printHeight"]').value = '4.48819'
      document.querySelector('[data-participants-target="printHeight"]').readOnly = true
    }
    for (var i = 0; i < this.figureSizeTarget.options.length; i++) {
      if (this.figureSizeTarget.options[i].value == size) {
        this.figureSizeTarget.options[i].selected = true;
      }
    }
    this.toggleFigureSize()
  }

  ornamentRadioValue() {
    for(let i = 0; i < this.ornamentTypeTargets.length; i++) {
      if(this.ornamentTypeTargets[i].checked)
        return this.ornamentTypeTargets[i].value
    }
  }

  adjustPossibleScaleType() {
    // depending on the number of participants we adjust which scale types are possible
    const participantCount = document.querySelector('#pricingTotal')?.pricing?.participantCountValue
    // console.log('adjustPossibleScaleType', participantCount)
    if(!participantCount) // init race condition
      return

    if (participantCount < 2) {
      for(let i = 0; i < this.scaleTypeTargets.length; i++) {
        if(this.scaleTypeTargets[i].value === 'scale_to_selected') {
          // console.log('adjustPossibleScaleType - hiding', this.scaleTypeTargets[i])
          hide(this.scaleTypeTargets[i].parentNode)
        } else if (this.scaleTypeTargets[i].value === 'scale_to_figurine') {
          // console.log('adjustPossibleScaleType - showing', this.scaleTypeTargets[i])
          show(this.scaleTypeTargets[i].parentNode)
        }
      }
    }
    else {
      for(let i = 0; i < this.scaleTypeTargets.length; i++) {
        if(this.scaleTypeTargets[i].value === 'scale_to_selected') {
          // console.log('adjustPossibleScaleType - showing', this.scaleTypeTargets[i])
          show(this.scaleTypeTargets[i].parentNode)
        } else if (this.scaleTypeTargets[i].value === 'scale_to_figurine') {
          // console.log('adjustPossibleScaleType - hiding', this.scaleTypeTargets[i])
          hide(this.scaleTypeTargets[i].parentNode)
        }
      }
    }
  }

  toggleScaleType() {
    console.log('toggleScaleType', this.scaleTypeRadioValue())
    switch(this.scaleTypeRadioValue()){
      case 'scale_to_figurine':
        show(this.figureSizeWrapperTarget)
        hide(this.scaleParticipantsWrapperTarget)
        // this.relativeScaleTarget.disabled = true
        hide(this.scaleToWrapperTarget)
        this.denominatorTarget.disabled = true
        break;
      case 'scale_to_selected':
        show(this.scaleParticipantsWrapperTarget)
        hide(this.figureSizeWrapperTarget)
        // this.relativeScaleTarget.disabled = true
        hide(this.scaleToWrapperTarget)
        this.denominatorTarget.disabled = true
        break;
      case 'input_relative_scale':
        // this.relativeScaleTarget.disabled = false
        hide(this.figureSizeWrapperTarget)
        hide(this.scaleParticipantsWrapperTarget)
        hide(this.scaleToWrapperTarget)
        this.denominatorTarget.disabled = false
        break;
      case 'scale_to_other_order_item':
        show(this.scaleToWrapperTarget)
        hide(this.figureSizeWrapperTarget)
        // this.relativeScaleTarget.disabled = true
        hide(this.scaleParticipantsWrapperTarget)
        this.denominatorTarget.disabled = true
        break;
    }
  }

  scaleTypeRadioValue() {
    for(let i = 0; i < this.scaleTypeTargets.length; i++) {
      if(this.scaleTypeTargets[i].checked)
        return this.scaleTypeTargets[i].value
    }
  }

  calcScanHeight() {
    console.log('calc scan height')
    if(this.scanHeightFeetTarget.value && this.scanHeightInchesTarget.value)
      this.scanHeightTarget.value = Number(this.scanHeightFeetTarget.value) * 12 + Number(this.scanHeightInchesTarget.value)
    else
      console.log('need both feet & inches before calcing')

    this.calcReferenceHeight()
  }

  toggleScaleParticipant() {
    console.log('scale participant change')
    this.toggleScaleParticipantFigureSize()  // in case you change the participant after setting the figure size
  }

  toggleScaleTo() {
    console.log('toggleScaleTo', this.relativeScaleTarget.value)
    // get the scale from the selected items dataset
    this.relativeScaleTarget.value =  this.scaleToTarget.selectedOptions[0].dataset.scale
    this.denominatorTarget.value = (1 / this.relativeScaleTarget.value).toFixed(3);
    // call changeRelativeScale to calculate rest of form
    this.changeRelativeScale()
  }

  toggleScaleParticipantFigureSize() {
    console.log('scale participant figure size change', this.scaleParticipantTarget.value, this.relativeScaleTarget.value)
    // look up the ref height for the selected part (scaleParticipantTarget)
    const refHeight = document.querySelector(`[data-scaling-row-participant-id-value="${this.scaleParticipantTarget.value}"].scaling-row`).scalingRow.refHeightTarget.value
    const poseHeight = document.querySelectorAll(".scaling-row")[0]?.scalingRow?.poseHeightCalculatedTarget?.value
    const biggerHeight = Math.max(refHeight, poseHeight)
    // do the math to figure out the relative scale required to get this ref height to the selected figure size (scaleParticipantFigureSizeTarget)
    // set local relative scale to that value.
    // console.log(refHeight, this.scaleParticipantFigureSizeTarget.value)
    this.relativeScaleTarget.value = this.scaleParticipantFigureSizeTarget.value / biggerHeight
    this.denominatorTarget.value = (1 / this.relativeScaleTarget.value).toFixed(3);

    // call changeRelativeScale to calculate rest of form
    this.changeRelativeScale()
  }

  toggleFigureSize() {
    console.log('toggle figure size - existing value:', this.relativeScaleTarget.value)
    if(!document.querySelectorAll(".scaling-row")[0])
      return

    if (this.scaleTypeRadioValue() == "input_relative_scale")
      this.figureSizeTarget.value = ""

    const refHeight = document.querySelectorAll(".scaling-row")[0]?.scalingRow?.refHeightTarget?.value
    const poseHeight = document.querySelectorAll(".scaling-row")[0]?.scalingRow?.poseHeightCalculatedTarget?.value
    const biggerHeight = Math.max(refHeight, poseHeight)
    console.log('toggle figure size vals', biggerHeight, '=', this.figureSizeTarget.value, '=', Number(this.figureSizeTarget.value.replace('inch_', '')), this.relativeScaleTarget.value)
    if (this.figureSizeTarget.value)
      this.relativeScaleTarget.value = Number(this.figureSizeTarget.value.replace('inch_', '')) / biggerHeight
      this.denominatorTarget.value = (1 / this.relativeScaleTarget.value).toFixed(3);

    // call changeRelativeScale to calculate rest of form
    this.changeRelativeScale()
  }

  toggleQuantity() {
    document.querySelectorAll('.pricing-row').forEach(r=> {r?.pricingRow.setQuantity(this.quantityTarget.value)})
  }

  calcScaleParticipantsItems() {
    const selectRows = document.getElementById('participants').querySelectorAll("[data-controller=participants]")
    let values = []
    selectRows.forEach(p => (values.push({name: p.participants.name(), id: p.participants.idValue })))
    console.log('calcScaleParticipantsItems', selectRows, values)

    // empty the select out
    while (this.scaleParticipantTarget.options.length > 0) {
      console.log('removing ', this.scaleParticipantTarget)
      this.scaleParticipantTarget.remove(0);
    }

    // repop with new values - first one is blank
    this.scaleParticipantTarget.appendChild(new Option('', null))
    values.forEach(v=> {
      this.scaleParticipantTarget.appendChild(new Option(v.name, v.id))
    })
  }

  denominatorInput() {
    this.relativeScaleTarget.value = 1 / this.denominatorTarget.value
    this.changeRelativeScale()
  }
}
